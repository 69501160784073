import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useSupportDebugStore = defineStore({
    id: 'supportDebug',

    state: () => ({
        item: null
    }),

    actions: {
        show(item) {
            this.item = item
            useModal().show('debug')
        }
    }
})

export default useSupportDebugStore
