import api from '@/api'

import { nextTick } from 'vue'
import { defineStore } from 'pinia'

export const useSupportReleaseNotesStore = defineStore({
    id: 'supportReleaseNotes',

    state: () => ({
        isNavigationInitialized: false,

        items: [],

        title: null,
        content: null
    }),

    actions: {
        initialize() {
            this.load()
        },

        load() {
            if (this.isNavigationInitialized) return

            api.route('support release-notes index').get().json(res => {
                this.items = res

                this.isNavigationInitialized = true
            })
        },

        loadPage(page) {
            this.title = null
            this.content = null

            return api.route('support release-notes details', page).get().json(res => {
                this.title = res.title
                this.content = res.content
            })
        },

        scrollTop() {
            document.querySelector('#app-content').scrollTo(0, 0)
        },

        async setSection(section) {
            await nextTick()

            document.querySelector("a[id='" + section + "']").scrollIntoView(true)
            document.querySelector('#app-content').scrollBy(0, -50)
        },

        click(event) {
            event.preventDefault()

            if (event.target.hasAttribute('href')) {
                if (event.target.getAttribute('href').startsWith('#')) {
                    this.setSection(event.target.getAttribute('href').substring(1))
                }
            }

            return event
        }
    }
})

export default useSupportReleaseNotesStore
