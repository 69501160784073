import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useModalsDeleteConfirmationStore = defineStore({
    id: 'modalsDeleteConfirmation',

    state: () => ({
        entity: null,
        dependents: {},

        accept: null,
        reject: null,
        submitting: false
    }),

    getters: {
        hasDependents(store) {
            return Object.values(store.dependents).some(dependents => Object.values(dependents).length)
        }
    },

    actions: {
        async open(entity, dependents = {}) {
            this.submitting = false

            this.entity = entity
            this.dependents = dependents

            useModal().show('delete-confirmation')

            return new Promise((accept, reject) => {
                this.accept = accept
                this.reject = reject
            })
        },

        async confirm() {
            this.submitting = true

            this.accept()
            useModal().hide('delete-confirmation')
        },

        async cancel() {
            this.submitting = true

            this.reject('cancelled')
            useModal().hide('delete-confirmation')
        }
    }
})

export default useModalsDeleteConfirmationStore
