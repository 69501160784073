import { defineStore } from 'pinia'
import useAccountSettingsStore from "@/stores/settings/account";

export const useSettingsAccountPage = defineStore({
    id: 'settingsAccountPage',

    getters: {
        title: () => 'Account | Settings'
    },

    actions: {
        beforeEnter() {
            useAccountSettingsStore().load()
        }
    }
})

export default useSettingsAccountPage
