export function downloadBlob(data, fileName, mimeType) {
    mimeType = {
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'csv': 'text/csv'
    }[mimeType] || mimeType;

    let blob = new Blob([ data ], { type: mimeType })
    let url = URL.createObjectURL(blob)

    downloadUrl(url, fileName, mimeType)

    URL.revokeObjectURL(url)
}

export function downloadUrl(url, fileName, mimeType) {
    let anchor = document.createElement('a')
    anchor.href = url
    anchor.download = fileName
    anchor.style.display = 'none'

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
}
