<template>
    <div>
        <ui-header :title="'Release notes - ' + title"></ui-header>

        <div class="p-8 mx-auto mb-8 max-w-5xl bg-white shadow hover:shadow-lg">
            <release-notes-content v-if="content"></release-notes-content>
            <ui-spinner v-else></ui-spinner>
        </div>
    </div>
</template>

<script>
import useSupportReleaseNotesPage from '@/stores/support/release-notes'

import Content from '@/components/support/release-notes/content'

import { mapState } from 'pinia'

export default {
    components: {
        'release-notes-content': Content
    },

    computed: {
        ...mapState(useSupportReleaseNotesPage, [ 'title', 'content' ])
    }
}
</script>
