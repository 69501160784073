<template>
    <form @submit.prevent="submit" ref="form">
        <slot :submitting="store.submitting" :submitted="store.submitted" :inputs="store.inputs" :has-errors="store.hasErrors" :errors="store.errors"></slot>
    </form>
</template>

<script>
export default {
    methods: {
        submit() {
            this.store.submit()
        }
    }
}
</script>
