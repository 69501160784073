<template>
    <a @click.prevent="$modal.show('workspaces')" class="flex items-center justify-center text-sm font-medium text-gray-600 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out h-9 w-9 mx-auto mb-2" href="#" v-tooltip.right="$my.currentWorkspace.name" v-if="$my.currentWorkspace">
        <ui-avatar :item="$my.currentWorkspace" :icon="avatarIcon" class="w-8 h-8 cursor-pointer"></ui-avatar>
    </a>
</template>

<script>
export default {
    computed: {
        avatarIcon() {
            if (this.$my.currentWorkspace.type == 'global') return 'globe-simple'
            if (this.$my.currentWorkspace.type == 'personal') return 'user'
            return undefined
        }
    }
}
</script>
