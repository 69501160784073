const options = [
    { name: 'Arabic', id: 'ar', icon: 'flag-sa' },
    { name: 'Belarusian', id: 'be', icon: 'flag-by' },
    { name: 'Bulgarian', id: 'bg', icon: 'flag-bg' },
    { name: 'Chinese', id: 'zh', icon: 'flag-cn' },
    { name: 'Czech', id: 'cs', icon: 'flag-cz' },
    { name: 'Danish', id: 'da', icon: 'flag-dk' },
    { name: 'Dutch', id: 'nl', icon: 'flag-nl' },
    { name: 'English', id: 'en', icon: 'flag-uk' },
    { name: 'Estonian', id: 'et', icon: 'flag-ee' },
    { name: 'Finnish', id: 'fi', icon: 'flag-fi' },
    { name: 'French', id: 'fr', icon: 'flag-fr' },
    { name: 'German', id: 'de', icon: 'flag-de' },
    { name: 'Greek', id: 'el', icon: 'flag-gr' },
    { name: 'Hebrew', id: 'he', icon: 'flag-il' },
    { name: 'Hindi', id: 'hi', icon: 'flag-in' },
    { name: 'Hungarian', id: 'hu', icon: 'flag-hu' },
    { name: 'Irish', id: 'ga', icon: 'flag-ie' },
    { name: 'Italian', id: 'it', icon: 'flag-it' },
    { name: 'Korean', id: 'ko', icon: 'flag-kr' },
    { name: 'Latvian', id: 'lv', icon: 'flag-lv' },
    { name: 'Japanese', id: 'ja', icon: 'flag-jp' },
    { name: 'Lithuanian', id: 'lt', icon: 'flag-lt' },
    { name: 'Norwegian', id: 'no', icon: 'flag-no' },
    { name: 'Polish', id: 'pl', icon: 'flag-pl' },
    { name: 'Portuguese', id: 'pt', icon: 'flag-pt' },
    { name: 'Romanian', id: 'ro', icon: 'flag-ro' },
    { name: 'Russian', id: 'ru', icon: 'flag-ru' },
    { name: 'Serbian', id: 'sr', icon: 'flag-rs' },
    { name: 'Slovak', id: 'sk', icon: 'flag-sk' },
    { name: 'Slovenian', id: 'sl', icon: 'flag-si' },
    { name: 'Spanish', id: 'es', icon: 'flag-es' },
    { name: 'Swedish', id: 'sv', icon: 'flag-se' },
    { name: 'Turkish', id: 'tr', icon: 'flag-tr' },
    { name: 'Ukrainian', id: 'uk', icon: 'flag-ua' },
    {},
    { name: 'Afrikaans', id: 'af', icon: '' },
    { name: 'Albanian', id: 'sq', icon: '' },
    { name: 'Armenian', id: 'hy', icon: '' },
    { name: 'Azerbaijani', id: 'az', icon: '' },
    { name: 'Bashkir', id: 'ba', icon: '' },
    { name: 'Basque', id: 'eu', icon: '' },
    { name: 'Bosnian', id: 'bs', icon: '' },
    { name: 'Breton', id: 'br', icon: '' },
    { name: 'Burmese', id: 'my', icon: '' },
    { name: 'Catalan', id: 'ca', icon: '' },
    { name: 'Central Khmer', id: 'km', icon: '' },
    { name: 'Chuvash', id: 'cv', icon: '' },
    { name: 'Corsican', id: 'co', icon: '' },
    { name: 'Croatian', id: 'hr', icon: '' },
    { name: 'Esperanto', id: 'eo', icon: '' },
    { name: 'Galician', id: 'gl', icon: '' },
    { name: 'Ganda', id: 'lg', icon: '' },
    { name: 'Georgian', id: 'ka', icon: '' },
    { name: 'Gujarati', id: 'gu', icon: '' },
    { name: 'Indonesian', id: 'id', icon: '' },
    { name: 'Icelandic', id: 'is', icon: '' },
    { name: 'Interlingua', id: 'ia', icon: '' },
    { name: 'Interlingue', id: 'ie', icon: '' },
    { name: 'Kazakh', id: 'kk', icon: '' },
    { name: 'Kirghiz', id: 'ky', icon: '' },
    { name: 'Kurdish', id: 'ku', icon: '' },
    { name: 'Latin', id: 'la', icon: '' },
    { name: 'Luxembourgish', id: 'lb', icon: '' },
    { name: 'Macedonian', id: 'mk', icon: '' },
    { name: 'Malay', id: 'ms', icon: '' },
    { name: 'Marathi', id: 'mr', icon: '' },
    { name: 'Mongolian', id: 'mn', icon: '' },
    { name: 'Norwegian Bokmål', id: 'nb', icon: '' },
    { name: 'Norwegian Nynorsk', id: 'nn', icon: '' },
    { name: 'Occitan', id: 'oc', icon: '' },
    { name: 'Ossetian', id: 'os', icon: '' },
    { name: 'Panjabi', id: 'pa', icon: '' },
    { name: 'Pashto', id: 'ps', icon: '' },
    { name: 'Persian', id: 'fa', icon: '' },
    { name: 'Sinhala', id: 'si', icon: '' },
    { name: 'Shona', id: 'sn', icon: '' },
    { name: 'Somali', id: 'so', icon: '' },
    { name: 'Sotho', id: 'st', icon: '' },
    { name: 'Swahili', id: 'sw', icon: '' },
    { name: 'Tagalog', id: 'tl', icon: '' },
    { name: 'Tamil', id: 'ta', icon: '' },
    { name: 'Tatar', id: 'tt', icon: '' },
    { name: 'Telugu', id: 'te', icon: '' },
    { name: 'Thai', id: 'th', icon: '' },
    { name: 'Tibetan', id: 'bo', icon: '' },
    { name: 'Tsonga', id: 'ts', icon: '' },
    { name: 'Tswana', id: 'tn', icon: '' },
    { name: 'Urdu', id: 'ur', icon: '' },
    { name: 'Uzbek', id: 'uz', icon: '' },
    { name: 'Vietnamese', id: 'vi', icon: '' },
    { name: 'Welsh', id: 'cy', icon: '' },
    { name: 'Western Frisian', id: 'fy', icon: '' },
    { name: 'Xhosa', id: 'xh', icon: '' },
    { name: 'Yiddish', id: 'yi', icon: '' },
    { name: 'Yoruba', id: 'yo', icon: '' },
    { name: 'Zhuang', id: 'za', icon: '' },
    {},
    { name: 'Unknown', id: '00', icon: '' }
]

export default options
