<template>
    <div class="h-full w-full">
        <slot :is-loading="isLoading" :is-loaded="isLoaded" v-if="isLoaded"></slot>
        <slot name="loading" v-else>
            <div class="flex items-center justify-center py-4 w-full h-full">
                <ui-spinner v-bind="spinner"></ui-spinner>
            </div>
        </slot>
    </div>
</template>

<script>
let notSet = {}

export default {
    props: { guards: { default: () => notSet }, spinner: {} },

    computed: {
        isLoading() {
            return ! this.guards
        },

        isLoaded() {
            return ! this.isLoading
        }
    }
}
</script>
