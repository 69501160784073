import useAuthPasswordRecoveryResetForm from '@/stores/auth/password-recovery-reset-form'

import { defineStore } from 'pinia'

export const useAuthPasswordRecoveryResetPage = defineStore({
    id: 'authPasswordRecoveryResetPage',

    actions: {
        beforeEnter(to) {
            useAuthPasswordRecoveryResetForm().setResetToken(to.params.token, to.query.email)
        }
    }
})

export default useAuthPasswordRecoveryResetPage
