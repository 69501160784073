import useAuthLoginForm from '@/stores/auth/login-form'

import { defineStore } from 'pinia'

export const useAuthLoginPage = defineStore({
    id: 'authLoginPage',

    actions: {
        beforeEnter(to) {
            useAuthLoginForm().setActivationToken('')
        }
    }
})

export default useAuthLoginPage
