<template>
    <div ref="content" class="leading-loose release-notes-text" @click.stop="click" v-html="content"></div>
</template>

<script>
import useSupportReleaseNotesPage from '@/stores/support/release-notes'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useSupportReleaseNotesPage, [ 'title', 'content' ]),
    },

    methods: {
        ...mapActions(useSupportReleaseNotesPage, [ 'click' ])
    }
}
</script>

<style>
.release-notes-text h1 {
    font-size: 22px;
    font-weight: bolder;
    line-height: 24px;
    padding: .8rem 0;
    margin-left: -10px
}

.release-notes-text h2 {
    font-size: 18px;
    font-weight: bolder;
    line-height: 20px;
    padding: .8rem 0;
    margin-left: -10px
}

.release-notes-text h3 {
    font-size: 15px;
    font-weight: bolder;
    line-height: 17px;
    padding: .8rem 0;
    margin-left: -10px
}

.release-notes-text p {
    padding: .4rem 0
}

.release-notes-text p a {
    @apply text-blue-700 font-semibold
}

.release-notes-text p:after {
    content: "";
    display: inline-block;
    width: 0;
}

.release-notes-text ul, ol {
    white-space: normal;
    padding: 0 .5rem 1rem .5rem
}

.release-notes-text ul {
    list-style-type: disc
}

.release-notes-text ol {
    list-style-type: decimal
}

.release-notes-text li {
    @apply pb-0.5
}

.release-notes-text .table-of-contents {
    @apply bg-blue-50 border border-blue-200 font-medium pl-8 py-3 mb-3
}

.release-notes-text .table-of-contents ul {
    @apply pb-0
}

.release-notes-text .heading-permalink {
    @apply invisible
}

.release-notes-text p.hint-content {
    padding: 0
}

.release-notes-text .hint h2 {
    font-size: 15px;
    line-height: 17px;
    padding-top: 6px;
    margin-left: 0
}

.release-notes-text .hint.note {
    @apply bg-blue-50 border-l-4 border-blue-800 px-4 py-2 my-3 rounded-r
}

.release-notes-text .hint.note h2 {
    @apply text-blue-700
}

.release-notes-text .hint.important {
    @apply bg-orange-50 border-l-4 border-orange-600 px-4 py-2 my-3 rounded-r
}

.release-notes-text .hint.important h2 {
    @apply text-orange-700
}

.release-notes-text .hint.danger {
    @apply bg-red-50 border-l-4 border-red-600 px-4 py-2 my-3 rounded-r
}

.release-notes-text .hint.danger h2 {
    @apply text-red-700
}
</style>
