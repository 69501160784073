import debounce from 'just-debounce-it'
import { reactive } from 'vue'

const screens = {
    xs: 320,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xl2: 1536,
    xl3: 1836
}

const breakpoint = reactive({ w: 0, h: 0, is: 'xs' })

const xs = val => val >= screens.xs && val < screens.sm
const sm = val => val >= screens.sm && val < screens.md
const md = val => val >= screens.md && val < screens.lg
const lg = val => val >= screens.lg && val < screens.xl
const xl = val => val >= screens.xl && val < screens.xl2
const xl2 = val => val >= screens.xl2 && val < screens.xl3
const xl3 = val => val >= screens.xl3

const getBreakpoint = w => {
    if (xs(w)) return 'xs'
    else if (sm(w)) return 'sm'
    else if (md(w)) return 'md'
    else if (lg(w)) return 'lg'
    else if (xl(w)) return 'xl'
    else if (xl2(w)) return '2xl'
    else if (xl3(w)) return '3xl'
    else return 'all'
}

const setBreakpoint = () => {
    breakpoint.w = window.innerWidth
    breakpoint.h = window.innerHeight
    breakpoint.is = getBreakpoint(window.innerWidth)
}

setBreakpoint()
window.addEventListener('resize', debounce(() => {
    setBreakpoint()
}, 50))

export default breakpoint
