<template>
    <div class="relative rounded-full w-24 h-24 shadow mx-auto mb-4 overflow-hidden group">
        <img :src="previewSrc || (src || '/img/dummy_profile.jpeg')" class="w-full h-full object-cover">
        <label class="opacity-0 group-hover:opacity-100 transition absolute inset-0 bg-gray-600 bg-opacity-70 flex justify-center items-center text-gray-200 text-3xl cursor-pointer">
            <ui-icon name="pencil"></ui-icon>
            <input type="file" :name="name" accept="image/*" hidden @change="change">
        </label>
    </div>
</template>

<script>
export default {
    props: [ 'name', 'src', 'value' ],

    data: () => ({
        previewSrc: null
    }),

    methods: {
        change($event) {
            let reader = new FileReader()
            reader.onload = (e) => this.previewSrc = e.target.result
            reader.readAsDataURL($event.target.files[0])

            this.$emit('update:modelValue', $event.target.files[0])
        }
    }
}
</script>
