<template>
    <div v-if="effectiveIcon && ! isEmoji" class="rounded-full bg-gray-800 text-white flex items-center justify-center">
        <div v-if="isEmoji" class="text-4xl">{{effectiveIcon}}</div>
        <ui-icon v-else :name="effectiveIcon" class="text-3xl"></ui-icon>
    </div>

    <ui-icon v-else-if="effectiveFamily == 'curated-lists'" name="navigation.sub.curated-lists" :style="`color: ${avatarColor};`"></ui-icon>

    <svg v-else-if="effectiveFamily && path" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <template v-if="effectiveImage">
            <image width="32" height="32" :clip-path="`path('${path}')`" :xlink:href="effectiveImage"></image>
        </template>
        <template v-else>
            <path :d="path" :fill="avatarColor"></path>
            <text fill="#ffffff" font-family="ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif" font-size="13" font-weight="500" letter-spacing=".05em" x="50%" y="50%" dominant-baseline="central" text-anchor="middle">
                {{ effectiveIcon && isEmoji ? effectiveIcon : initials }}
            </text>
        </template>
    </svg>

    <img v-else-if="effectiveImage" :src="effectiveImage" class="rounded-full bg-gray-800">

    <svg v-else viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" :fill="avatarColor"></circle>
        <text fill="#ffffff" font-family="ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif" font-size="13" font-weight="500" letter-spacing=".05em" x="50%" y="50%" dominant-baseline="central" text-anchor="middle">
            {{ effectiveIcon && isEmoji ? effectiveIcon : initials }}
        </text>
    </svg>
</template>

<script>
import { colorHash } from '@/helpers'

export default {
    props: [ 'color', 'family', 'icon', 'image', 'item', 'name' ],

    computed: {
        effectiveColor() { return this.color || this.item?.avatar?.color },
        effectiveFamily() { return this.family || this.item?.family },
        effectiveIcon() { return this.icon || this.item?.avatar?.icon },
        effectiveImage() { return this.image || this.item?.avatar?.imageUrl },
        effectiveName() { return this.name || this.item?.name || this.item?.title },

        initials() {
            if (! this.effectiveName) return ''

            let names = this.effectiveName.replace(/[^\p{L}\p{N} ]+/u, '').toUpperCase().split(' ').map(name => name.trim())
            let initials = ''

            initials += names[0][0] || ''
            initials += names[1] ? names[1][0] : (names[0][1] || '')

            return initials
        },

        avatarColor() {
            if (this.effectiveColor) return this.effectiveColor

            return this.effectiveName ? colorHash(this.effectiveName) : 'currentColor'
        },

        isEmoji() {
            return this.effectiveIcon.match(/\p{Emoji}/ug)
        },

        path() {
            return {
                perspectives: 'M21.9838 1H10.0131C9.30533 0.999571 8.60994 1.19356 7.99709 1.5624C7.38424 1.93124 6.87559 2.46189 6.52245 3.10084L0.538666 13.9024C0.185766 14.5399 0 15.2629 0 15.9988C0 16.7347 0.185766 17.4577 0.538666 18.0952L6.52555 28.8992C6.8787 29.5381 7.38734 30.0688 8.00019 30.4376C8.61304 30.8064 9.30843 31.0004 10.0162 31H21.9838C22.6915 31.0003 23.3869 30.8062 23.9997 30.4374C24.6125 30.0686 25.1212 29.538 25.4744 28.8992L31.4613 18.0952C31.8142 17.4577 32 16.7347 32 15.9988C32 15.2629 31.8142 14.5399 31.4613 13.9024L25.4713 3.10084C25.1181 2.462 24.6094 1.93144 23.9966 1.56262C23.3838 1.19379 22.6884 0.999736 21.9807 1',
                topics: 'M16,32l-5.333,-4.292l-7.111,-0c-1.947,-0 -3.556,-1.791 -3.556,-3.958l0,-19.792c0,-2.167 1.609,-3.958 3.556,-3.958l24.888,0c1.947,0 3.556,1.791 3.556,3.958l0,19.792c0,2.167 -1.609,3.958 -3.556,3.958l-7.111,-0l-5.333,4.292Z',
                lists: 'M12.8009 0H3.20418C2.30201 0.0670011 1.46185 0.498299 0.865989 1.20031C0.27013 1.90232 -0.0333546 2.81841 0.0213877 3.7498L0.00552534 26.2502C-0.0457211 27.1834 0.261099 28.0999 0.859655 28.8016C1.45821 29.5033 2.30041 29.9338 3.20418 30H28.7958C29.6995 29.9338 30.5417 29.5033 31.1403 28.8016C31.7389 28.0999 32.0457 27.1834 31.9944 26.2502V7.50041C32.0459 6.56708 31.7392 5.65035 31.1406 4.94848C30.542 4.24662 29.6997 3.81601 28.7958 3.7498H16.0004L12.8009 0Z',
                analyses: 'M1 29V3C1 1.34315 2.34315 0 4 0H22.815C23.8615 0 24.8323 0.545282 25.3768 1.43889L30.5618 9.94761C30.8484 10.4179 31 10.958 31 11.5087V29C31 30.6569 29.6569 32 28 32H4C2.34315 32 1 30.6569 1 29Z'
            }[this.effectiveFamily]
        }
    }
}
</script>
