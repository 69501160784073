import useSupportHelpStore from '@/stores/support/help'

import { defineStore } from 'pinia'

export const useSupportHelpPagePage = defineStore({
    id: 'supportHelpPagePage',

    getters: {
        title: () => 'Help'
    },

    actions: {
        async beforeEnter(to) {
            useSupportHelpStore().loadNavigation()
            await useSupportHelpStore().loadPage(to.params.page)

            if (to.params.section) {
                setTimeout(() => {
                    useSupportHelpStore().setSection(to.params.section)
                }, 1000)
            }
        }
    }
})

export default useSupportHelpPagePage
