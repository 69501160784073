import throttle from 'just-throttle'
import { reactive } from 'vue'

let appContainer
const scroll = reactive({ position: 0 })

const setScroll = () => {
    scroll.position = appContainer.scrollTop
}

export const trackScrolling = () => {
    appContainer = document.querySelector('#app-content')

    if (! appContainer) return

    setScroll()
    appContainer.addEventListener('scroll', throttle(() => {
        setScroll()
    }, 5, { trailing: true }), { passive: true })
}

export default scroll
