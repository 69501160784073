<template>
    <vue-eternal-loading v-model:is-initial="isInitial" spinner="spiral" :load="load">
        <template v-slot:loading>
            <ui-spinner class="text-sm"></ui-spinner>
        </template>
        <template v-slot:no-more>
            <div></div>
        </template>
        <template v-slot:no-results>
            <div></div>
        </template>
    </vue-eternal-loading>
</template>

<script>
import { VueEternalLoading } from '@ts-pro/vue-eternal-loading'

export default {
    components: { VueEternalLoading },

    props: [ 'identifier' ],

    data: () => ({
        isInitial: true
    }),

    methods: {
        load({loaded, noMore, noResults, error}) {
            this.$emit('infinite', {
                loaded,
                complete: noMore,
                noResults,
                error
            })
        }
    },

    watch: {
        identifier(newVal, oldVal) { if (newVal != oldVal) this.isInitial = true }
    }
}
</script>
