// Palette based on the default TailwindCSS color palette
// https://tailwindcss.com/docs/customizing-colors#color-palette-reference
let palette = [
    '#dc2626', // red
    '#ea580c', // orange
    '#d97706', // amber
    '#ca8a04', // yellow
    '#65a30d', // lime
    '#16a34a', // green
    '#059669', // emerald
    '#0d9488', // teal
    '#0891b2', // cyan
    '#0284c7', // sky
    '#2563eb', // blue
    '#4f46e5', // indigo
    '#7c3aed', // violet
    '#9333ea', // purple
    '#c026d3', // fuschia
    '#db2777', // pink
    '#e11d48' // rose
]

import crc32 from 'crc32'

export default name => {
    let hash = crc32(name)
    let index = parseInt(hash, 16) % palette.length

    return palette[index]
}
