import useSupportReleaseNotesStore from '@/stores/support/release-notes'

import { defineStore } from 'pinia'

export const useSupportReleaseNotesPage = defineStore({
    id: 'supportReleaseNotesPage',

    getters: {
        title: () => 'Release Notes'
    },

    actions: {
        async beforeEnter(to) {
            await useSupportReleaseNotesStore().initialize()
            await useSupportReleaseNotesStore().loadPage(to.params.page)
        }
    }
})

export default useSupportReleaseNotesPage
