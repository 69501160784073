import { defineStore } from 'pinia'

export const useAuthPasswordRecoveryRequestPage = defineStore({
    id: 'authPasswordRecoveryRequestPage',

    state: () => ({
        submitted: false
    }),

    actions: {
        beforeEnter(to) {
            this.$reset()
        }
    }
})

export default useAuthPasswordRecoveryRequestPage
