import api from '@/api'

import { nextTick } from 'vue'
import { defineStore } from 'pinia'
import { useRouter } from '@/helpers'

export const useSupportHelpStore = defineStore({
    id: 'supportHelp',

    state: () => ({
        mode: null,
        title: null,
        content: null,
        createdAt: null,
        updatedAt: null,

        navigation: null,

        isOverlayOpen: false
    }),

    actions: {
        show(page, section) {
            if (! page) return

            this.showOverlay()

            this.loadPage(page)

            if (section) {
                setTimeout(() => {
                    this.setSection(section)
                }, 1000)
            }
        },

        loadNavigation() {
            if (this.navigation) return

            api.route('support help index').get().json(res => {
                this.navigation = res
            })
        },

        loadPage(page) {
            this.mode = 'help'
            this.title = null
            this.content = null

            return api.route('support help details', page).get().json(res => {
                this.title = res.title
                this.createdAt = res.createdAt
                this.updatedAt = res.updatedAt
                this.content = res.content
            })
        },

        loadReleaseNotes(page) {
            this.mode = 'releaseNotes'
            this.title = null
            this.content = null

            return api.route('support release-notes details', page).get().json(res => {
                this.title = res.title
                this.content = res.content
            })
        },

        scrollTop() {
            document.querySelector('#app-content').scrollTo(0, 0)
        },

        async setSection(section) {
            await nextTick()

            document.querySelector("a[id='" + section + "']").scrollIntoView(true)
            document.querySelector('#app-content').scrollBy(0, -50)
        },

        showOverlay() {
            this.isOverlayOpen = true
        },

        hideOverlay() {
            this.isOverlayOpen = false
        },

        parseLink(link) {
            return link.substring(1).split('#')
        },

        click(event) {
            event.preventDefault()

            if (event.target.hasAttribute('href')) {
                if (event.target.getAttribute('href').startsWith('/')) {
                    const [page, section] = this.parseLink(event.target.getAttribute('href'))

                    if (this.isOverlayOpen) {
                        this.loadPage(page)

                        if (section) {
                            setTimeout(() => {
                                this.setSection(section)
                            }, 1000)
                        }
                    } else {
                        useRouter().push({ name: 'help.page', params: { page, section } })
                    }
                }

                if (event.target.getAttribute('href').startsWith('#')) {
                    this.setSection(event.target.getAttribute('href').substring(1))
                }
            }

            return event
        }
    }
})

export default useSupportHelpStore
